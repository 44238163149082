<!-- <div class="navbar">
        <a ><strong>Property Portal</strong> </a>
        <a></a>
        <a></a>

        <a></a>

        <a></a>

        <a></a>

        <a href="/landing"><button class="btn" ><i class="fa fa-home"></i> Home</button></a>
        
    
      </div> -->






<div class="background">

  <div class="formGroup">
    <form (ngSubmit)="userLogin()">
      <div [formGroup]="loginForm">


        <br>
        
        <div class="input-group mb-3">
          <input class="inputClass" type="text" nbInput placeholder="Company Code" autofocus formControlName="companyCode"
            id="companyCode" />
         
        </div>
        <small *ngIf="!loginForm.get('companyCode').valid && loginForm.get('companyCode').touched">
          <b > Please Enter Valid Company Code </b></small>


        <div class="input-group mb-3">
          <input class="inputClass" type="text" nbInput placeholder="User Name" autofocus formControlName="loginId" id="userId" />
         
        </div>
        <small *ngIf="!loginForm.get('loginId').valid && loginForm.get('loginId').touched">
          <b> Please Enter Valid User Name </b></small>


        <div class="input-group mb-3">
          <input class="inputClass" type="password" nbInput placeholder="Password" autofocus formControlName="password" id="password" />
          
        </div>
        <small *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">
          <b> Please Enter Valid Password </b></small>
      </div>


      <div class="form-group row">
        <small [hidden]="invalidData">
          <b> Invalid Credentials !!!</b></small>
      </div>
      <div class="form-group row ">
      <div class="col-md-6; buttons">
        <!-- <button type="submit" class="button" (click)="userLogin()"><b>Login</b> </button> -->
        <button class="btn  ">Login</button>
      
        <!-- <button type="submit" class="button" (click)="Register()"><b>Register</b> </button> -->
       
      </div>
      <div class="col-md-6 ; ">
         <button class="btn  ; selfBtn" (click)="Register()">Self Registration For First Time Seller</button>
      </div>
    </div>
      <div class="form-group row">
        <div class="col-md-4"></div>
        <div class="col-md-6">
          <button class="forgot" (click)="goToForgot()">Forgot Password?</button>
        </div>
      </div>

      <div class=" contact">
        <p> For Support and Technical Help
          <br> Please contact at <b>8860797403</b>
        </p>
      </div>
    </form>

  </div>




</div>








<div class="footer">
  <span class="created-by">
    © 2023, Developed by Ananata Futuristic Solutions (P) Ltd.( www.powererp.in ) V.2.5
  </span>
</div>
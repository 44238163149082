import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router } from '@angular/router';
import { LayoutService } from '../../../@core/utils';
import { UserloginService } from '../../../services/userloginService';
import { ErrorCode } from '../../../Globle';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  currentTheme = 'default';
  logout: any;
  // userPicture = "../../../../assets/images/no-user.png";
  userMenu = [{ title: 'Logout' }];

  constructor(private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService, private userloginService: UserloginService,
    private route: Router,
    private sanitizer: DomSanitizer,
    private _location: Location
  ) {

  }

  loginId
  companyCode
  userName
  ngOnInit() {
    var payload
    payload = JSON.parse(sessionStorage.getItem('payload'));
    if(payload !=null){
    this.companyCode = payload[0].companyCode
    this.loginId = payload[0].loginId
    this.userName = payload[0].name
    this.currentTheme = this.themeService.currentTheme;
    this.logout = this.menuService.onItemClick()
      .subscribe((event) => {
        if (event.item.title === 'Logout') {
          sessionStorage.clear();
          this.userLogout();
          this.route.navigate(['/login']);
        }
      });
      // this.getCompanylogo(this.companyCode);
    }
    if(payload ==null){
      this.currentTheme = this.themeService.currentTheme;
      this.logout = this.menuService.onItemClick()
        .subscribe((event) => {
          if (event.item.title === 'Logout') {
            sessionStorage.clear();
            this.userLogout();
            this.route.navigate(['/login']);
          }
        });
      }
  }
  gotoLogin()
  {
    //console.log("in gotoLogin");
    this.route.navigate(['/login']); 
    // this.route.navigate(['/pages/login']);

    // this.route.navigate(['/pages/masters/register']);
  }
 
  resp
  userLogout() {
    this.userloginService.userLogOut(this.loginId).subscribe(response => {
      this.resp = response;
    })
  }

  ngOnDestroy() {
    this.logout.unsubscribe();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  img
  name
  getCompanylogo(companyId){
    var companyLogo
    //console.log("companyId ",companyId);
    this.userloginService.getCompanyLogo(companyId).subscribe((
      response =>{
        companyLogo = response
        //console.log("logo ",companyLogo);
        
        this.name = companyLogo.name
        let objectURL = 'data:image/jpeg;base64,' + companyLogo.image;
        // Globle.imageBlobUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);  
        // this.img = Globle.imageBlobUrl
        this.img = this.sanitizer.bypassSecurityTrustUrl(objectURL);  
      }
    ))
  }


  backClicked(){
    this._location.back();
  }

  goToHome(){

  }

navigateHome(){

}

}

import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
// import { DataService } from '../services/data.service';
// import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { LoginService } from '../services/loginService';
import { Login } from '../models/login';

import { Role } from '../models/role';
import { ReportFilter, Globle } from '../Globle';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  constructor(private loginService: LoginService, private route: Router) { }
  ngOnInit() {
    this.loginForm = new FormGroup({
      loginId: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      companyCode: new FormControl(null, Validators.required),
      // roleId:new FormControl(null, Validators.required)
    })
  }
  invalidData = true;
  myPayload
  loginResponse
  async userLogin() {
    //console.log(this.loginForm.value, "form vl");
    this.loginService.userLogin(this.loginForm.value).subscribe(
      data => {
        this.loginResponse = data;
        //console.log(this.loginResponse, "response");


        if (this.loginResponse.length > 0 && this.loginResponse[0].status != false) {
          // let myPayload = JSON.parse(sessionStorage.getItem('payload'));
          sessionStorage.setItem('payload', JSON.stringify(this.loginResponse));
          Globle.user = this.loginResponse;
          if (this.loginResponse[0].roleId == 110) {
            this.route.navigate(['/pages/masters/vendor-profile']);
          } else {
            this.route.navigate(['/pages/masters/home-page'])
          }
        }
        else {

          this.invalidData = false;
          this.loginForm = new FormGroup({
            loginId: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required),
            companyCode: new FormControl(null, Validators.required)

          })
        }
      }
    )

  }

  Register() {
    this.route.navigate(['/pages/masters/user-registration']);
    // this.route.navigate(['/landing-page']); 



  }

  goToForgot() {

  }
}

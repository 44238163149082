<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Vendor Onboarding Portal</a>
    <a (click)="backClicked()" ><img src="../../../../assets/images/back.png" alt="Back" width="30px;" height="25px"/></a>
    </div>
 
</div>

  <!-- <button class="btn ;selected" (click)="goToHome()">
  <i class="fa fa-home" aria-hidden="true"></i>Home
</button> -->


<div class="user_logo">
  <nb-actions size="small">

  
    
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
       <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               name={{loginId}}
               >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>


import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from '../app/login/login.component';
import { PagesComponent } from './pages/pages.component';
import { HomePageComponent } from './pages/masters/home-page/home-page.component';
import { VendorProfileComponent } from './pages/masters/vendor-profile/vendor-profile.component';



export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
 
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login'},
  { path: 'login', component:LoginComponent},

  // { path: '', redirectTo: 'home-page', pathMatch: 'full' },
  // { path: '**', redirectTo: 'home-page'},
  // { path: 'home-page', component:HomePageComponent},


  //   path:'all-property', component:AllPropertyComponent
  // },
  

 
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

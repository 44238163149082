import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Login } from "../models/login";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";

@Injectable(
  {
    providedIn: 'root'
  }
)

export class LoginService {
 
  constructor(private http: HttpClient) { }
  private baseUrl = `${environment.apiUrl}/portal/userLogin`;

  // userLogin(companyId, userId, pwd) {
  //   return this.http.get<Login[]>(`${environment.apiUrl}/tms/userlogin/findlogin/` + companyId + `/` + userId + `/` + pwd).pipe(map(res => res))
  // }
userLogin ( loginForm)

{
  return this.http.post(`${this.baseUrl}/userLogin`,loginForm);
  
  
}

  searchLoginId(term) {
    return this.http.get<Login[]>(`${environment.apiUrl}/tms/userlogin/finduser/` + term ).pipe(map(res => res))
  }

  getForms(reportFilter: Object): Observable<Object> {
    var obj=this.http.post(`${environment.apiUrl}/tms/formauthentication/getforms`, reportFilter);
        return obj;
  }
  registration(register: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/userlogin/register`, register,{observe : 'response'});
  }
  registrationWithFile(register: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/userlogin/registerWithFile`, register,{observe : 'response'});
  }
  genrateOTP(email){
    return this.http.get(`${environment.apiUrl}/tms/userlogin/genrateOtp/`+email)
  }
  sms(register){
    // const httpOptions = {
    //   headers: new HttpHeaders({ 
    //     'Access-Control-Allow-Origin':'*',
    //     'Authorization':'authkey',
    //     // 'userid':'1'
    //   })
    // };httpOptions
    // return this.http.post(`http://localhost:7071/fams/sendSms/smsPost1`, register);
    return this.http.post('http://biz.sms4power.com/Api/smsapi/JsonPost',register)
  }
  getEmail(email : string){
    return this.http.get<String[]>(`${environment.apiUrl}/tms/userlogin/getEmail/`+email)
  }
  getContactNo(contactNo : string){
    return this.http.get<String[]>(`${environment.apiUrl}/tms/userlogin/getContactNo/`+contactNo)
  }
}


